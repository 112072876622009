/* .MenuTwoTable {
    display: flex;
    flex-wrap: wrap;
    margin-left: 50px;
    font-size: 25px;
} */

 .MenuTwoTable {
     width: 100%;
     display: flex;
     flex-direction: column;
 }

@media screen and (max-width: 800px) {
    .MenuTwoTable {
        margin-left: 5%;
        font-size: 4px;

        
    }
  }

@media screen and (max-width: 450px) {
    .MenuTwoTable {
        margin-left: 10px;
        font-size: 15px;
        
    }
  }

  @media screen and (min-width:800px) and (max-width: 1600px) {
    .MenuTwoTable {
        margin-left: 10px;
        font-size: 15px;

        
    }
  }

.Paperhead {
    background-color: #81e98f90;
}

.Paperbody {
    background-color: #81e98f50;
}